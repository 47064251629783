@font-face {
  font-family: 'Geometric';
  font-display: swap;
  font-weight: 400;
  src: local('Geometric'), url(./fonts/GeometricSans_otf/GeometricSans-Regular.otf) format('truetype');
}

@font-face {
  font-family: 'Geometric';
  font-display: swap;
  font-weight: 500;
  src: local('Geometric'), url(./fonts/GeometricSans_otf/GeometricSans-Medium.otf) format('truetype');
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Geometric';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a, a:visited {
  color: 'black';
  text-decoration: none; 
}

a:hover {
  text-decoration: underline;
}

.mobile-link:hover {
  text-decoration: none;
}